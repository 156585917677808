<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Thống kê quà còn lại</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-12">

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Tên thử thách:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          v-model="paramFilter.title"
                          placeholder="Nhập tên thử thách..."
                      />
                    </div>

                    <div class="col-lg-4 mb-lg-0 mb-6">
                      <label>Thời gian:</label>
                      <div class="input-daterange input-group" id="kt_datepicker">
                        <input
                            type="date"
                            v-model="paramFilter.date_start"
                            class="form-control datatable-input"
                            name="start"
                            placeholder="Từ"
                        />
                        <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                        </div>
                        <input
                            type="date"
                            v-model="paramFilter.date_end"
                            class="form-control datatable-input"
                            name="end"
                            placeholder="Đến"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button class="btn btn-primary btn-primary--icon">
                        <span>
                          <i class="la la-search"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                    </div>

                  </div>

                </b-form>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >

                <template #cell(icon)="data">
                  <img width="50px" :src="data.item.icon">
                </template>

                <template #cell(remain)="data">
                  <span>
                    {{ data.item.total - data.item.release }}
                  </span>
                </template>

              </b-table>

              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>

            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        title: "",
        date_start: '',
        date_end: '',
      },
      fieldsLog: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "icon",
          label: "Hình ảnh",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Tên thử thách",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total",
          label: "Tổng quà",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "release",
          label: "Đã phát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "remain",
          label: "Còn lại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        }
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê nhiệm vụ", route: "/campaign/report/missions" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportGifts(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.items;
        this.paginate = body.meta;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },

  },
};
</script>